import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

//gatsby
import { Link, useStaticQuery, graphql } from "gatsby"

//component
import Layout from "components/Layout"
import ThirdLayerHeader from "components/ThirdLayerHeader"
import ThirdLayerClose from "components/ThirdLayerClose"
import { ThirdLayer, Main } from "components/ThirdLayerBody"
import RandDmats from "components/imageComps/RandDmats"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from "../../components/SEO"

const VarietyOfFiber = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.labo.pass}`,
          "name": `${data.seo.page.labo.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${data.seo.page.labo.varietyFiber.pass}`,
          "name": `${data.seo.page.labo.varietyFiber.title}`,
        }
      }]
    }
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.labo.varietyFiber.title}
          pageUrl={data.seo.page.labo.varietyFiber.pass}
          description={data.seo.page.labo.varietyFiber.description}
          imgUrl='/mainVisual.jpg'
          type='article'
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.varietyOfFiber.leadText}
            parentPath={parentPath}
          />
          <Main>
            <p>{data.text.varietyOfFiber.paragraph1}</p>
            <p>{data.text.varietyOfFiber.paragraph2}</p>
            <p>{data.text.varietyOfFiber.paragraph3}</p>
            <RandDmats />
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "laboPage" }) {
      varietyOfFiber {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        labo {
          description
          pass
          title
          varietyFiber {
            pass
            description
            title
          }
        }
      }
    }
  }
`

export default VarietyOfFiber
