import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FlexCenter } from '../CssTools'


const StyledWrap = styled.div`
  width:100%;
  padding:0 16px;
  display:flex;
  flex-flow:row wrap;
  align-items:flex-start;
  justify-content:space-between;
  ${() => media.tablet(css`
    max-width:600px;
    margin:0 auto;
  `)}
  p{
    font-size:12px;
    line-height:1.1em;
  }
  h3{
    width:100%;
    font-size:12px;
    margin:30px 0 16px;
    ${FlexCenter}
    line-height:14px;
    flex-flow:row nowrap;
    span{
      font-family:${({ theme }) => theme.colors.font.eng};
      margin:0 8px;
      font-size:14px;
      font-weight:800;
    }
  }
`

const StyledPhoto2 = styled.div`
  width:48%;
  .gatsby-image-wrapper{
    height:100px;
      ${() => media.tablet(css`
    height:200px!important;
  `)}

  }
`

const RandDmats = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <h3>
        <span>[</span>
        {data.text.varietyOfFiber.imgtxt[0]}
        <span>]</span>
      </h3>
      <StyledPhoto2>
        <StaticImage
          src='../../images/soyMiniImage.png'
          placeholder='none'
          alt='大豆'
        />
        <p>{data.text.varietyOfFiber.imgtxt[1]}</p>
      </StyledPhoto2>
      <StyledPhoto2>
        <StaticImage
          src='../../images/potatoImage.png'
          placeholder='none'
          alt='イヌリア'
        />
        <p>{data.text.varietyOfFiber.imgtxt[2]}</p>
      </StyledPhoto2>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "laboPage"}) {
      varietyOfFiber {
        imgtxt
      }
    }
  }
`

export default RandDmats
